import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import LayoutEng from "../components/LayoutEng"
import SEO from "../components/SEO"

const TagTemplate = ({ data, pageContext }) => {
  const { html } = data.markdownRemark
  const { title, featuredImg } = data.markdownRemark.frontmatter
  return (
    <LayoutEng>
      <SEO title={pageContext.slug} />
      <main className="page tag-page">
        <h2><strong>{title}</strong></h2>
        <div className="tagImg">
        <GatsbyImage
        image={getImage(featuredImg.childImageSharp.gatsbyImageData)}
         />
        </div>
        <div className="pageText" dangerouslySetInnerHTML={{ __html: html }} />
        <div className="backBtn">
              <Link to="../../en/#tagsEng"  className="btn">
                &laquo; Back 
              </Link>
        </div>
      </main>
    </LayoutEng>
  )
}

export const query = graphql` 
query tagsTemplate($slug: String) {
  markdownRemark(frontmatter: {slug: {eq: $slug}}) {
    html
    frontmatter {
      slug
      description
      title
      featuredImg {
        childImageSharp {
          gatsbyImageData(
            placeholder: DOMINANT_COLOR
            transformOptions: {fit: CONTAIN}
            width: 800
          )
        }
      }
    }
  }
}
`


export default TagTemplate
